import React, { useState, useRef } from 'react';
import { CollectionInfoModal } from '../../../common/components/modals/CollectionInfo/CollectionInfoModal';
import { useToast } from '../../../common/context/ToastContext/ToastContext';
import { useEnvironment, useTranslation, IUser } from '@wix/yoshi-flow-editor';
import { st, classes } from './Collections.st.css';
import { PrivacySettings } from '../../../consts/collectionsConsts';
import { useCustomCallback } from '../../../common/hooks/useCustomCallback';
import { useCollectionsBi } from '../../../common/context/BiContext/BiContext';
import {
  openWizardCreateCollection,
  createCollection as createCollectionBi,
} from '@wix/bi-logger-photo-ugc/v2';
import ProGalleryWixWrapper from './Collections/CollectionsList/GalleryWixWrapper/ProGalleryWixWrapper';
import { CollectionsListHeader } from './Collections/CollectionsList/Header/CollectionsListHeader';
import { TEMPLATES_VERSION_8 } from '../../../consts/galleryConsts';
import { isAlbumTemplateV8 } from '../../../common/utils';

export type CollectionsWidgetProps = {
  mediaCollections: MediaCollections;
  host: any;
  galleries: MediaGalleries;
  collectionsWixCodeApi: GalleryWixCodeApiFunctions;
  navigator: INavigator;
  siteName: string;
  staticMediaUrls: any;
  onJoinCollection: (collectionId: string) => void;
  onLeaveCollection: (collectionId: string) => void;
  createCollection: ({
    name,
    privacySettings,
    description,
    origin,
  }: {
    name?: string;
    privacySettings?: PrivacySettings;
    description?: string;
    origin: string;
  }) => Promise<string>;
  loggedIn: boolean;
  onCreateModalOpen: () => void;
  actionCallbacks: Callbacks;
  instanceId: string;
  user: IUser;
  albumData: AlbumData;
};

export const CollectionsWidget = (props: CollectionsWidgetProps) => {
  const [createModalopen, setCreateModalopen] = useState(false);
  // const [collectionEditorOpen, setCollectionEditorOpen] = useState(false);
  const {
    navigator,
    mediaCollections,
    siteName,
    onJoinCollection,
    onLeaveCollection,
    createCollection,
    loggedIn,
    onCreateModalOpen,
    staticMediaUrls,
    instanceId,
    user,
    albumData,
  } = props;
  const { biLogger } = useCollectionsBi();
  const { triggerToast } = useToast();
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const [filteredCollections, setFilteredCollections] =
    useState(mediaCollections);
  const clickBiOrigin = useRef('Live site collections page ES');

  const onCreateCollection = async ({
    name,
    privacy_settings,
    description,
  }: CollectionDataForSave) => {
    createCollection({
      name,
      description,
      privacySettings: privacy_settings,
      origin: clickBiOrigin.current,
    });
  };

  useCustomCallback({
    actionCallbacks: props.actionCallbacks,
    functions: {
      openCreateModal: () => {
        setCreateModalopen(true);
      },
    },
  });
  const onCreateClickHandler = ({ clickOrigin }: { clickOrigin: string }) => {
    biLogger.log(createCollectionBi, {
      origin: clickOrigin,
    });
    clickBiOrigin.current = clickOrigin;
    onCreateModalOpen();
    biLogger.log(openWizardCreateCollection, {
      button_name: 'Create List',
      source: 'Collections Lists Page',
    });
  };
  return (
    <div
      data-hook="media-collections-app"
      className={st(classes.root, {
        mobile: isMobile,
        responsive: props.host.isResponsive,
      })}
    >
      <>
        <CollectionsListHeader
          navigator={navigator}
          onCreate={onCreateClickHandler}
          siteName={siteName}
          mediaCollections={mediaCollections}
          onFilter={(option: CollectionsListFilterOption) =>
            setFilteredCollections(option.collections)
          }
          emptyState={!mediaCollections || mediaCollections.length === 0}
          isAlbumV8={isAlbumTemplateV8(albumData.settings)}
        />
        <ProGalleryWixWrapper
          staticMediaUrls={staticMediaUrls}
          mediaCollections={filteredCollections}
          navigator={navigator}
          onCreate={onCreateClickHandler}
          siteName={siteName}
        />
      </>

      {createModalopen && (
        <CollectionInfoModal
          onClose={() => setCreateModalopen(false)}
          onSave={onCreateCollection}
          isCreateCollection={true}
          isOpen={createModalopen}
        />
      )}
    </div>
  );
};
