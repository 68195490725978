import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';
import _ from 'lodash';
import { useResizeObserver } from '../../../../../../common/hooks/useResizeObserver';
import { WixProGallery } from '@wix/pro-gallery-wix-wrapper';
import {
  serverItemsToProGallery,
  proGalleryItemsToWixCode,
} from '@wix/pro-gallery-items-formatter';
import './ProGalleryWixWrapper.scss';
import { listsDefaultStyleParams } from '../../../../../../consts/defautStyleParams';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { defaultGalleryItem } from '../../../../../../consts/galleryConsts';
import { translateWithParams } from '../../../../../../common/utils';

const RESIZE_OBSERVER_DELAY = 300;
type WixProGalleryWrapperType = {
  mediaCollections: MediaCollections;
  staticMediaUrls: any;
  navigator: INavigator;
  onCreate: any;
  siteName: string;
};
const ProGalleryWixWrapper = (props: WixProGalleryWrapperType) => {
  const { mediaCollections, staticMediaUrls, navigator } = props;
  const { isMobile } = useEnvironment();
  const defaultContainer = {
    scrollBase: 0,
    width: 1000,
    height: 1000,
    isDefaultContainer: true,
  };

  const containerRef = useRef<any>(null);
  const [container, setContainer] = useState<any>(defaultContainer);
  const [height, setHeight] = useState(container.height);
  const { t } = useTranslation();
  const memoizedGalleryItems = useMemo(() => {
    const items = mediaCollections.map(
      (collection: CollectionAndStatus, idx: number) => {
        if (collection.mediaCollection.items.length > 0) {
          return collection.mediaCollection.items[0];
        } else {
          return defaultGalleryItem;
        }
      },
    );
    const galleryItems: WixCodeItem[] = proGalleryItemsToWixCode(
      serverItemsToProGallery(items),
    );
    galleryItems.forEach((item: WixCodeItem, idx: number) => {
      const {
        mediaCollection: {
          itemsCount,
          membersCount,
          privacySettings,
          id,
          name,
        },
      } = mediaCollections[idx];
      item.link = id;
      item.title = name;
      item.description = translateWithParams(
        t,
        'GALLERY_INFO_ELEMENT_LOL_PAGE',
        {
          privacySettings,
          membersCount,
          itemsCount,
        },
      );
    });
    return galleryItems;
  }, [mediaCollections]);

  const eventsListener = useCallback((eventName: string, eventData: any) => {
    switch (eventName) {
      case 'ITEM_FOCUSED':
        navigator.collection(eventData.linkUrl);
        break;
      default:
        break;
    }
  }, []);

  const resizeGallery = useCallback(() => {
    setContainer({
      width: containerRef.current?.clientWidth,
      height: containerRef.current?.clientHeight,
      isDefaultContainer: false,
    });
  }, []);

  const resizeObserverCallback = useCallback(() => {
    _.debounce(resizeGallery, RESIZE_OBSERVER_DELAY)();
  }, [resizeGallery]);

  useEffect(() => {
    resizeGallery();
  }, []);

  useResizeObserver({
    ref: containerRef,
    callback: resizeObserverCallback,
  });

  return (
    <div id="lists-gallery-container" style={{ height }}>
      <div ref={containerRef}>
        <WixProGallery
          id="collections-lists-gallery"
          deviceType={isMobile ? 'mobile' : 'desktop'}
          isMobile={isMobile}
          itemsSrc="wixSDK"
          staticMediaUrls={staticMediaUrls}
          items={memoizedGalleryItems}
          styles={listsDefaultStyleParams}
          container={container}
          eventsListener={eventsListener}
          scrollingElement={containerRef.current}
          viewMode="SITE"
          setLayoutHeightImp={setHeight}
          experimentalFeatures={{}} // FIXME: why is this mandatory?
        />
      </div>
    </div>
  );
};

export default ProGalleryWixWrapper;
