import { Status } from '../../../../../../../consts/collectionsConsts';

export const getFilteringOptions = (
  mediaCollections: MediaCollections,
  t: (key: string) => string,
) => {
  const options = [
    {
      id: '1',
      value: `${t('COLLECTIONS_LIST_FILTER_ALL_COLLECTIONS')} (${
        mediaCollections.length
      })`,
      collections: mediaCollections,
      isSelectable: true,
    },
  ];
  const { UNDEFINED, ...statusOptionsWithoutUndefined } = Status;
  Object.values(statusOptionsWithoutUndefined).forEach((value, index) => {
    const filterCollections = mediaCollections.filter(
      (collectionAndStatus: CollectionAndStatus) =>
        collectionAndStatus.memberStatus === value,
    );
    const option = {
      id: String(index + 2),
      value:
        t(`COLLECTIONS_MEMBER_ROLE_${value}`) +
        ` (${filterCollections.length})`,
      collections: filterCollections,
      isSelectable: true,
    };
    options.push(option);
  });

  return options;
};
