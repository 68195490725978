import React from 'react';
import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';
import { CollectionsListFilter } from './CollectionsListFilter/CollectionsListFilter';
import { style, classes } from './CollectionsListHeader.st.css';
import { Text, TextButton, TextButtonPriority } from 'wix-ui-tpa';
import Add from 'wix-ui-icons-common/Add';
import { CollectionsListEmptyState } from '../CollectionsListEmptyState';

type CollectionsListHeaderProps = {
  onCreate: ({ clickOrigin }: { clickOrigin: string }) => void;
  navigator: INavigator;
  siteName: string;
  mediaCollections: MediaCollections;
  onFilter: (selectedOption: CollectionsListFilterOption) => void;
  emptyState: boolean;
  isAlbumV8?: boolean;
};

export const CollectionsListHeader = ({
  onCreate,
  navigator,
  siteName,
  mediaCollections,
  onFilter,
  emptyState,
  isAlbumV8,
}: CollectionsListHeaderProps) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  return (
    <>
      {!isAlbumV8 && !isMobile && (
        <Text className={classes.header}>
          {t('COLLECTIONS_BREADCRUMBS_COLLECTIONS_LIST')}
        </Text>
      )}

      {emptyState ? (
        <div className={classes.emptyStateWrapper}>
          <CollectionsListEmptyState
            onCTAClick={() =>
              onCreate({ clickOrigin: 'Live site collections page ES' })
            }
          />
        </div>
      ) : (
        <div
          className={style(isMobile ? classes.mobileHeader : classes.root, {
            mobile: isMobile,
          })}
        >
          <CollectionsListFilter
            mediaCollections={mediaCollections}
            onFilter={onFilter}
          />
          <CreateButton
            onCreate={() =>
              onCreate({ clickOrigin: 'Live site collections page list' })
            }
            isMobile={isMobile}
            content={t(
              isMobile
                ? 'COLLECTIONS_LIST_CREATE_CTA_MOBILE'
                : 'COLLECTIONS_LIST_CREATE_CTA',
            )}
          />
        </div>
      )}
    </>
  );
};

const CreateButton = ({
  onCreate,
  isMobile,
  content,
}: {
  onCreate: () => void;
  isMobile: boolean;
  content: string;
}) => {
  const buttonProps = {
    onClick: onCreate,
    className: style(classes.createButton, { mobile: isMobile }),
  };

  return (
    <TextButton
      {...buttonProps}
      prefixIcon={<Add />}
      priority={TextButtonPriority.secondary}
    >
      {content}
    </TextButton>
  );
};
