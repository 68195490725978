import React from 'react';
import {
  useExperiments,
  useTranslation,
  useEnvironment,
} from '@wix/yoshi-flow-editor';
import { st, classes } from './Widget.st.css';
import { CollectionsWidget, CollectionsWidgetProps } from './Collections';
import { ToastProvider } from '../../../common/context/ToastContext/ToastContext';
import { TPAComponentsProvider } from 'wix-ui-tpa';
import { RootPropsProvider } from '../../../common/context/RootPropsContext/RootPropsContext';
import { BiProvider } from '../../../common/context/BiContext/BiContext';

export default (props: CollectionsWidgetProps) => {
  const { isMobile } = useEnvironment();
  const { instanceId, user, albumData } = props;

  const biContextProps = {
    albumId: albumData?.settings?.id,
    instance_id: instanceId,
    visitorId: user?.id,
    role: user?.role,
  };

  return (
    <div data-hook="media-collections-wrapper">
      <RootPropsProvider value={props}>
        <TPAComponentsProvider value={{ mobile: isMobile, rtl: false }}>
          <BiProvider {...biContextProps}>
            <ToastProvider>
              <CollectionsWidget {...props} />
            </ToastProvider>
          </BiProvider>
        </TPAComponentsProvider>
      </RootPropsProvider>
    </div>
  );
};
