import React, { useEffect, useState, useRef } from 'react';
import { FloatingDropdown } from 'wix-ui-tpa';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { getFilteringOptions } from './collectionsListFilteringOptions';
import { st, classes } from './CollectionsListFilter.st.css';

type CollectionsListFilter = {
  mediaCollections: MediaCollections;
  onFilter: (selectedOption: CollectionsListFilterOption) => void;
};

export const CollectionsListFilter = ({
  mediaCollections,
  onFilter,
}: CollectionsListFilter) => {
  const isInitialMount = useRef(true);
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const filteringOptions = getFilteringOptions(mediaCollections, t);
  const defaultOption = filteringOptions[0];

  const [currentOption, setCurrentOption] = useState(defaultOption);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      setCurrentOption(defaultOption);
    }
  }, [mediaCollections]);

  return (
    <FloatingDropdown
      className={st(classes.root, { mobile: isMobile })}
      options={filteringOptions}
      label=""
      placeholder={defaultOption.value}
      value={currentOption.id}
      onChange={(option: any) => {
        setCurrentOption(option);
        onFilter(option);
      }}
    ></FloatingDropdown>
  );
};
