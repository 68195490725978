import React from 'react';
import { Button,Text  } from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { st, classes } from './CollectionsListEmptyState.st.css';

type CollectionsListEmptyState = {
  onCTAClick: () => void;
};

export const CollectionsListEmptyState = ({
  onCTAClick,
}: CollectionsListEmptyState) => {
  const { t } = useTranslation();
  return (
    <div className={classes.emptyState}>
      <div className={classes.emptyStateContent}>
        <Text className={classes.title}>
          {t('COLLECTIONS_LIST_EMPTY_STATE_TITLE')}
        </Text>
        <Text className={classes.subTitle}>
          {t('COLLECTIONS_LIST_EMPTY_STATE_SUBTITLE')}
        </Text>
        <Button
          data-hook="empty-state-create-list-btn"
          onClick={onCTAClick}
          className={classes.emptyStateAction}
        >
          {t('COLLECTIONS_LIST_EMPTY_STATE_CTA')}
        </Button>
      </div>
    </div>
  );
};
